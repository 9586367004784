import { $vfm } from "vue-final-modal";
import { getBaseUrl } from "~~/utils";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$axios.onRequest(async (req) => {
    req.headers["Accept-Language"] = nuxtApp.$i18n.locale.value;
    req.headers["Lang"] = nuxtApp.$i18n.locale.value;
    req.baseURL = getBaseUrl();


    if (req.url !== 'settings' && !req.url.includes('version')) {
      req.headers['Authorization'] = localStorage.getItem('auth._token.local') || req.headers['AuthBase'];
    }
    req.headers['AuthBase'] = req.headers['Authbase'] = null

    const { $auth } = useNuxtApp();
    if ($auth.isRefreshing) {
      try {
        await $auth.refreshPromise;
        return Promise.resolve(req);
      } catch (e) {
        return true;
      }
    } else {
      return Promise.resolve(req);
    }
  });
  nuxtApp.$axios.onResponse((response) => {
    if (response?.data?.response?.notify) {
      const { content, type } = response?.data?.response?.notify;
      nuxtApp.$showToast(content, type || "success");

      const keys = Object.keys(response?.data?.response);
      const requireSocial = keys.find((x) => /^(vksub|tgsub):.+/.test(x));
      const requireBot = keys.find((x) => ["tg_bot"].includes(x));
      if (requireSocial) {
        $vfm.show("socials", {
          hideVk: response?.config?.url === "bonus/newyear/bonus",
        });
      } else if (requireBot) {
        $vfm.show("telegram");
      }
    } else if (response?.data?.error) {
      const type = typeof response?.data?.error;
      if (type == "string") nuxtApp.$showToast(response?.data?.error, "error");
      else if (type === "object") {
        Object.keys(response?.data?.error).forEach((key) => {
          response?.data?.error[key].forEach((msg) => {
            nuxtApp.$showToast(msg, "error");
          });
        });
      }
    } else if (response?.data?.message) {
      if(response.config.url !== '/games/wheels/megawheel/bet')
        nuxtApp.$showToast(response?.data?.message, "success");
    } else if (response?.data?.msg) {
      nuxtApp.$showToast(response?.data?.msg, "success");
    }
    return Promise.resolve(response);
  });
  nuxtApp.$axios.onError(async (res) => {
    const localePath = useLocalePath()

    if (res?.name === "ExpiredAuthSessionError") {
      const router = useRouter();
      router.push(localePath("/"));
    } else if (res.response?.status === 401) {
      // const { $auth } = useNuxtApp();
      // await $auth.logout().catch(() => { });
      // const router = useRouter();
      // router.push(localePath("/"));
    } else if (res.response?.status === 403) {
      const { t } = useI18n();
      throw Error(t("error.403"), {
        statusCode: 403,
      });
    }
    const { response } = res;
    if (response?.data?.error) {
      const type = typeof response?.data?.error;
      if (type == "string" && response?.data?.error !== "Unauthorized") nuxtApp.$showToast(response?.data?.error, "error");
      else if (type === "object") {
        Object.keys(response?.data?.error).forEach((key) => {
          response?.data?.error[key].forEach((msg) => {
            if (msg !== "Unauthorized") nuxtApp.$showToast(msg, "error");
          });
        });
      }
    } else if (response?.data?.response?.notify) {
      const { content, type } = response?.data?.response?.notify;
      nuxtApp.$showToast(content, type || "success");
    }
    return Promise.reject(res);
  });
});
