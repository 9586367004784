export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"keywords","content":"trix, trix казино, трикс казино официальный сайт, трикс официальный сайт, trix официальный сайт, сайт трикс, trix сайт, трикс казино, trix казино официальный сайт, трикс фан, trix casino зеркало, трикс казино зеркало, казино трикс, trix зеркало казино, трикс сайт, трикс, trix casino, trix games, трикс официальный, трикс официальный сайт казино, трикс играть, трикс casino, трикс зеркало, трикс играть онлайн, трикс демо, trix официальный, трикс онлайн, трикс играть на деньги, trix fun, trix промокод, трикс промокод, trixfun, trix актуальный сайт, трикс игра"},{"name":"robots","content":"index,follow"},{"http-equiv":"content-type","content":"text/html; charset=UTF-8"},{"name":"author","lang":"ru","content":"TRIX"}],"link":[{"rel":"shortcut icon","type":"image/png","href":"/images/games/dice.png"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"