import ResizeObserver from "resize-observer-polyfill";
import { $vfm } from "vue-final-modal";

if (window) {
  window.ResizeObserver = ResizeObserver;
}

export default defineNuxtPlugin((app) => {
  addRouteMiddleware(
    "auth",
    async (route, from) => {
      if (route.meta?.auth) {
        const { $auth } = useNuxtApp();
        if (!$auth.loggedIn) {
          $vfm.show('auth')
          const localePath = useLocalePath()
          return from.meta.auth ? localePath('/') : from
        }
      }
    },
    { global: true }
  );
});
