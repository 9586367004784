import { $vfm } from "vue-final-modal";

export default defineNuxtPlugin((app) => {
  app.$router.options.scrollBehavior = () => {
    const el = document.querySelector("#root")
    if (el) el.scrollTo({ top: 0 })
  };



  addRouteMiddleware(
    "modal",
    async (route) => {
      const { $vfm } = app;
      if ($vfm.openedModals.length) $vfm.hideAll();
    },
    { global: true }
  );

  addRouteMiddleware(
    "opener",
    async (route) => {
      const modalname = route.query?.game
      try {
        if (modalname && /\d+/.test(modalname)) {
          if ($vfm.modals.find(x => x.props.name === 'game')) $vfm.show('game', { id: modalname })
          else setTimeout(() => {
            $vfm.show('game', { id: modalname })
          }, 2000)
        }
      } catch (e) {
        console.log(e);
      }
      const showModal = route.query?.modal
      if (showModal && ['payment', 'auth'].includes(showModal)) {
        if ($vfm.modals.find(x => x.props.name === showModal)) $vfm.show(showModal, {
          tab: 'deposit',
          system: route.query?.mid
        })
          else setTimeout(() => {
            $vfm.show(showModal, {
              tab: 'deposit',
              system: route.query?.mid
            })
          }, 2000)
      }
    },
    { global: true }
  );

});
