export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.log('error', error);
    console.log('error message', error?.message);
    if (
      error?.message?.includes &&
      (error.message.includes("ailed to fetch dynamically imported module") ||
        error.message.includes("mporting a module script failed") ||
        error.message.includes("nable to preload CSS") ||
        error.message.includes("error loading dynamically imported module") ||
        error.message.includes("nvalid or unexpected token") ||
        error.message.includes("is not a valid JavaScript MIME type"))
    ) {
      navigator?.serviceWorker?.getRegistrations().then(async (registrations) => {
        for (let registration of registrations) {
          await registration.unregister();
        }
      });
    }
  };
});
