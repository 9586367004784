export default function (nuxtApp, msg, callback) {
  if (msg?.data?.notify && nuxtApp) {
    const { content, msg: msgx, status, type } = msg?.data?.notify;
    nuxtApp.$showToast(content || msgx, type || status || "success");
  }

  if (nuxtApp && msg && callback) {
    callback(nuxtApp, msg);
  }
}
