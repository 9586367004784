
import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin } from '#imports'
// Active schemes
import { LocalScheme } from '#auth/runtime'

export default defineNuxtPlugin(async nuxtApp => {
    // Options
    const options = {
  "globalMiddleware": false,
  "enableMiddleware": true,
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": false,
  "pinia": {
    "namespace": "auth"
  },
  "cookie": false,
  "localStorage": {
    "prefix": "auth."
  },
  "sessionStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

    // Create a new Auth instance
    const auth = new Auth(nuxtApp, options)

    // Register strategies
    auth.registerStrategy('local', new LocalScheme(auth, {
  "user": {
    "property": "response",
    "autoFetch": false
  },
  "token": {
    "property": "response.access_token.token",
    "name": "AuthBase"
  },
  "refreshToken": false,
  "autoLogout": true,
  "endpoints": {
    "login": {
      "url": "/auth/login",
      "method": "post",
      "propertyName": "response"
    },
    "user": {
      "url": "/auth/me",
      "method": "get",
      "propertyName": "response"
    },
    "logout": false
  },
  "name": "local"
}));

    try {
        await auth.init();

        return {
            provide: {
                auth
            }
        }
    }
    catch {
        if (process.client) {
            // Don't console log expired auth session errors. This error is common, and expected to happen.
            // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
            // token. We don't want to log this as an error.
            if (error instanceof ExpiredAuthSessionError) {
                return
            }

            console.error('[ERROR] [AUTH]', error)
        }
    }
})