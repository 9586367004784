export default defineNuxtPlugin((app) => {
  addRouteMiddleware(
    "refs",
    async (route, from) => {
      if (route.name?.includes("refs-id")) {
        const refCode = useCookie("refCode", {
          maxAge: 60 * 60,
        });
        refCode.value = `${route.params.id}`.replace(/[^+\d]/g, '');
        const { $axios, $auth } = useNuxtApp();

        const referrer = route.query.utm_source || document.referrer;

        $axios
          .post(`ref/hit`, {
            refCode: route.params.id,
            referer: referrer,
            type: "link",
          })
          .catch(() => { });

        if ($auth.loggedIn) {
          await $axios
            .get(`ref/activate`, {
              params: {
                refCode: route.params.id,
              },
            })
            .catch(() => { });
        }
        return navigateTo(`/?ref=${route.params.id}`);
      } else if (from.query.ref && !route.query.ref) {
        route.query.ref = from.query.ref
        return route
      } else if (from.query.ref) {
        const refCode = useCookie("refCode", {
          maxAge: 60 * 60,
        });
        refCode.value = `${from.query.ref}`.replace(/[^+\d]/g, '');
      }
    },
    { global: true }
  );
});
