import Toast, { useToast } from "vue-toastification";
import ToastComponent from "@/components/VToast.vue";

const options = {
  // You can set your default options here
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, {
    icon: false,
    maxToasts: window?.innerWidth <= 980 ? 1 : 3,
  });
  return {
    provide: {
      showToast: (contentx, type = "success") => {
        const toast = useToast();
        const content = {
          component: ToastComponent,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            content: contentx,
            type,
          },
          // Listen and react to events using callbacks. In this case we listen for
          // the "click" event emitted when clicking the toast button
          listeners: {},
        };

        // Render the toast and its contents
        toast(content, { position: "bottom-left", type });
      },
    },
  };
});
