import Centrifuge from "centrifuge";
import { ref } from "vue";
import socketMiddleware from "@/sockets/middleware";
import userSocket from "@/sockets/user";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $auth, $config } = nuxtApp;
  const centrifuge = ref(null);
  centrifuge.value = new Centrifuge($config.public.SOCKET_FORCE ? $config.public.SOCKET_FORCE : $config.dev || window.location.host.includes("fwapve.trix.lol") ? $config.public.SOCKET_URL : `wss://c.${window.location.host}/connection/websocket`);

  const subscriptions = [];

  const subscribe = (name, callback, options = {}) => {
    const exists = subscriptions.find((x) => x.channel === name);

    if (exists) {
      exists.unsubscribe();
      subscriptions.splice(
        subscriptions.findIndex((x) => x.channel === name),
        1
      );

    }
    const sub = centrifuge.value.subscribe(name, (data) => {
      if (callback) socketMiddleware(nuxtApp, data, callback);
    });
    subscriptions.push(sub);
    return sub;
  };
  const connectSocket = () => {
    subscribe("broadcast", (ctx, data) => {
      if (data?.data?.reload) {
        if (window) {
          window.location.reload(true);
        }
      } else if (data?.data?.redirect) {
        const { hostname } = new URL(data?.data?.redirect);
        if (hostname !== window.location.hostname && window.location.hostname !== "trix.casino") window.location.href = data.data.redirect;
      }
    });
  };
  connectSocket();

  if ($auth.loggedIn) {
    if ($auth.refreshPromise) await $auth.refreshPromise;
    centrifuge.value.setToken($auth.user.centrifugo_token);
  } else {
    centrifuge.value.setToken($config.public.SOCKET_KEY);
  }

  watch(
    () => $auth.user?.centrifugo_token,
    async (newVal, oldVal) => {
      // centrifuge.value.disconnect();
      const token = $auth.user.centrifugo_token || $config.public.SOCKET_KEY;
      centrifuge.value.setToken(token);

      if (!$auth.user.centrifugo_token) {
        const userSubIndex = subscriptions.findIndex((x) => x.channel.startsWith("usr"));
        if (userSubIndex && subscriptions[userSubIndex]) {
          // test
          subscriptions[userSubIndex].unsubscribe();
          subscriptions.splice(userSubIndex, 1);
        }
      }

      centrifuge.value._disconnect("Reconnect", true);
      // connectSocket();
    }
  );
  centrifuge.value.connect();
  centrifuge.value
    .on("connect", (data) => {
      if ($auth.loggedIn) {
        subscribe(`usr#${$auth.user.id}`, userSocket);
      }
    })
    .on("error", (e) => {
      console.log("socket error", e);
    })
  //

  return {
    provide: {
      centrifuge: {
        socket: centrifuge,
        subscribe,
      },
    },
  };
});
