import { Buffer } from "buffer";

export function getBaseUrl() {
  const { $config } = useNuxtApp();
  if (!window) return $config.public.BASE_URL;
  const force = $config.public.FORCE_DOMAIN
  if (force) return force;
  if (window.location.origin.includes("localhost") || window.location.origin.includes(".pages.dev") || window.location.origin.includes("fwapve")) return $config.public.BASE_URL;
  return `${window.location.origin}/api/`;
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export async function getFingerprint(getData) {
  const lastDate = localStorage.getItem("fpDate");
  const lastCache = localStorage.getItem("fpCache");
  

  if (lastDate && lastDate > Date.now() && lastCache) {
    return lastCache;
  }

  try {
    const data = await getData();
    if (data?.visitorId) {
      const str = data.visitorId;


      localStorage.setItem("fpDate", Date.now() + 1000 * 60 * 60 * 24 * 7);
      localStorage.setItem("fpCache", str);

      return str;
    }
  } catch (e) {
    try {
      const fpPromise = await import('https://openfpcdn.io/fingerprintjs/v4')
      const fpPromise2 = await fpPromise.load()
      const data = await fpPromise2.get()


      if (data?.visitorId) {
        const str = data.visitorId;
  
  
        localStorage.setItem("fpDate", Date.now() + 1000 * 60 * 60 * 24 * 7);
        localStorage.setItem("fpCache", str);
  
        return str;
      }
    } catch (z) {
    }
  }
  return null;
}


function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function convertMsToTime(milliseconds,format = 'MM-SS') {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  seconds = seconds % 60
  minutes = minutes % 60

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24
  if (format === 'DD-HH-MM-SS')
    return [padTo2Digits(days), padTo2Digits(hours), padTo2Digits(minutes), padTo2Digits(seconds)]
  return [padTo2Digits(minutes), padTo2Digits(seconds)]
}

