import { fpjsPlugin } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const config = useRuntimeConfig();

  vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.FP_KEY,
      endpoint: config.public.FP_DOMAIN,
        scriptUrlPattern: `${config.public.FP_DOMAIN}web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
    },
  });
});
