export default function (ctx, msg) {
  if (ctx?.$auth) {
    const { $auth, $vfm, $bus } = ctx;
    if (msg?.data && $auth) {
      const { balance, luckywheel, megawheel, crazytime, redirect, wallet } = msg.data;
      if (balance) $auth.user.money = balance;
      if (luckywheel && ctx._route.name.includes("luckywheel")) {
        const { allwin, winmoney, x } = luckywheel;
        $vfm.show("lucky_gain", {
          total: allwin,
          gain: winmoney,
          x,
        });
      }
      if (megawheel && ctx._route.name.includes("megawheel")) {
        const { allwin, winmoney, x } = megawheel;
        $vfm.show("megawheel_gain", {
          total: allwin,
          gain: winmoney,
          x: x.x,
          color: x.color,
        });
        $bus.$emit("megawheel:gain", winmoney);
      }
      if (crazytime && ctx._route.name.includes("crazytime")) {
        $bus.$emit("crazytime:update", crazytime);
      }
      if (redirect) {
        window.location.href = redirect;
      }
      if (wallet?.waiting === false) {
        $bus.$emit("deposit:waiting", false);
      }
    }
  }
}
