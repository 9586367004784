import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/aml-policy.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/auth/social.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/bonus.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/bubbles.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type].vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type]/index.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/casino/game/[...id].vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/casino/provider/[name].vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/dice/bonus.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/dice/index.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/fairness.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/faq.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/hotline.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/index.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/kyc.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/mainx.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/megawheel.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/mines.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/policy.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/popit.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/refs/[id].vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/refs/index.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/responsible-gambling.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/statuses.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/support.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/support/[id].vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/support/create.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/support/index.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/terms.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/tournaments.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/tower.vue?macro=true";
import { default as _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta } from "/builds/novaspin/trix/old-frontend-vue3/pages/x100.vue?macro=true";
export default [
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.name ?? "aml-policy___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.path ?? "/aml-policy",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/aml-policy.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/aml-policy.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.name ?? "aml-policy___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.path ?? "/en/aml-policy",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/aml-policy.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47aml_45policy_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/aml-policy.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.name ?? "auth-social___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.path ?? "/auth/social",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/auth/social.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/auth/social.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.name ?? "auth-social___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.path ?? "/en/auth/social",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/auth/social.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47auth_47social_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/auth/social.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.name ?? "bonus___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.path ?? "/bonus",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/bonus.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/bonus.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.name ?? "bonus___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.path ?? "/en/bonus",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/bonus.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bonus_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/bonus.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.name ?? "bubbles___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.path ?? "/bubbles",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/bubbles.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/bubbles.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.name ?? "bubbles___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.path ?? "/en/bubbles",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/bubbles.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47bubbles_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/bubbles.vue").then(m => m.default || m)
  },
  {
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.path ?? "/casino/:type",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type].vue",
    children: [
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.name ?? "casino-type___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.path ?? "",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type]/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type]/index.vue").then(m => m.default || m)
  }
],
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.name ?? undefined,
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type].vue").then(m => m.default || m)
  },
  {
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.path ?? "/en/casino/:type",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type].vue",
    children: [
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.name ?? "casino-type___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.path ?? "",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type]/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type]/index.vue").then(m => m.default || m)
  }
],
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.name ?? undefined,
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47_91type_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/[type].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.name ?? "casino-game-id___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.path ?? "/casino/game/:id(.*)*",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/game/[...id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/game/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.name ?? "casino-game-id___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.path ?? "/en/casino/game/:id(.*)*",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/game/[...id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47game_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/game/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.name ?? "casino-provider-name___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.path ?? "/casino/provider/:name",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/provider/[name].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/provider/[name].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.name ?? "casino-provider-name___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.path ?? "/en/casino/provider/:name",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/casino/provider/[name].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47casino_47provider_47_91name_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/casino/provider/[name].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.name ?? "dice-bonus___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.path ?? "/dice/bonus",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/dice/bonus.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/dice/bonus.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.name ?? "dice-bonus___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.path ?? "/en/dice/bonus",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/dice/bonus.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47bonus_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/dice/bonus.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.name ?? "dice___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.path ?? "/dice",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/dice/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/dice/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.name ?? "dice___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.path ?? "/en/dice",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/dice/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47dice_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/dice/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.name ?? "fairness___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.path ?? "/fairness",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/fairness.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/fairness.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.name ?? "fairness___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.path ?? "/en/fairness",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/fairness.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47fairness_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/fairness.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.name ?? "faq___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.path ?? "/faq",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/faq.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.name ?? "faq___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.path ?? "/en/faq",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/faq.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.name ?? "hotline___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.path ?? "/hotline",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/hotline.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/hotline.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.name ?? "hotline___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.path ?? "/en/hotline",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/hotline.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47hotline_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/hotline.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.name ?? "index___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.path ?? "/",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.name ?? "index___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.path ?? "/en",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.name ?? "kyc___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.path ?? "/kyc",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/kyc.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.name ?? "kyc___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.path ?? "/en/kyc",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/kyc.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47kyc_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.name ?? "mainx___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.path ?? "/mainx",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/mainx.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/mainx.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.name ?? "mainx___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.path ?? "/en/mainx",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/mainx.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mainx_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/mainx.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.name ?? "megawheel___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.path ?? "/megawheel",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/megawheel.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/megawheel.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.name ?? "megawheel___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.path ?? "/en/megawheel",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/megawheel.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47megawheel_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/megawheel.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.name ?? "mines___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.path ?? "/mines",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/mines.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/mines.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.name ?? "mines___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.path ?? "/en/mines",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/mines.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47mines_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/mines.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.name ?? "policy___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.path ?? "/policy",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/policy.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.name ?? "policy___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.path ?? "/en/policy",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/policy.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47policy_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.name ?? "popit___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.path ?? "/popit",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/popit.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/popit.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.name ?? "popit___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.path ?? "/en/popit",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/popit.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47popit_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/popit.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.name ?? "refs-id___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.path ?? "/refs/:id",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/refs/[id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/refs/[id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.name ?? "refs-id___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.path ?? "/en/refs/:id",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/refs/[id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/refs/[id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.name ?? "refs___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.path ?? "/refs",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/refs/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/refs/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.name ?? "refs___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.path ?? "/en/refs",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/refs/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47refs_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/refs/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.name ?? "responsible-gambling___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.path ?? "/responsible-gambling",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/responsible-gambling.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/responsible-gambling.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.name ?? "responsible-gambling___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.path ?? "/en/responsible-gambling",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/responsible-gambling.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47responsible_45gambling_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/responsible-gambling.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.name ?? "statuses___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.path ?? "/statuses",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/statuses.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/statuses.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.name ?? "statuses___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.path ?? "/en/statuses",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/statuses.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47statuses_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/statuses.vue").then(m => m.default || m)
  },
  {
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.path ?? "/support",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support.vue",
    children: [
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.name ?? "support-id___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.path ?? ":id",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/[id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/[id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.name ?? "support-create___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.path ?? "create",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/create.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/create.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.name ?? "support___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.path ?? "",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/index.vue").then(m => m.default || m)
  }
],
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.name ?? undefined,
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support.vue").then(m => m.default || m)
  },
  {
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.path ?? "/en/support",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support.vue",
    children: [
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.name ?? "support-id___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.path ?? ":id",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/[id].vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/[id].vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.name ?? "support-create___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.path ?? "create",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/create.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47create_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/create.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.name ?? "support___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.path ?? "",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/support/index.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support/index.vue").then(m => m.default || m)
  }
],
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.name ?? undefined,
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47support_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/support.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.name ?? "terms___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.path ?? "/terms",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/terms.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.name ?? "terms___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.path ?? "/en/terms",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/terms.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47terms_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.name ?? "tournaments___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.path ?? "/tournaments",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/tournaments.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/tournaments.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.name ?? "tournaments___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.path ?? "/en/tournaments",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/tournaments.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tournaments_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/tournaments.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.name ?? "tower___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.path ?? "/tower",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/tower.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/tower.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.name ?? "tower___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.path ?? "/en/tower",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/tower.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47tower_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/tower.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.name ?? "x100___ru",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.path ?? "/x100",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/x100.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/x100.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.name ?? "x100___en",
    path: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.path ?? "/en/x100",
    file: "/builds/novaspin/trix/old-frontend-vue3/pages/x100.vue",
    children: [],
    meta: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta,
    alias: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.alias || [],
    redirect: _47builds_47novaspin_47trix_47old_45frontend_45vue3_47pages_47x100_46vueMeta?.redirect || undefined,
    component: () => import("/builds/novaspin/trix/old-frontend-vue3/pages/x100.vue").then(m => m.default || m)
  }
]