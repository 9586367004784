import { $vfm } from "vue-final-modal";
import { useStore } from "~~/store";
import { storeToRefs } from "pinia";
import slug from 'slugify'

export async function sendGoal(name) {
  const metrikaId = 92936868;
  return await new Promise((resolve) => {
    if (window?.ym) {
      try {
        ym(metrikaId, "reachGoal", name, {}, () => {
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
}

export async function sendUserId(id) {
  const metrikaId = 92936868;

  if (window?.ym && id) {
    try {
      window.ym(metrikaId, "userParams", {
        UserID: `${id}`
      })
    } catch (e) {
      console.log(e);
    }
  } else {

  }
}

export async function getNewYear() {
  /*if (!useNuxtApp) return;
  const { $axios } = useNuxtApp();
  await $axios.$get("bonus/newyear/init").then(({ response }) => {
    if (response && !response.is_collected) {
      const store = useStore();
      const { is_collected, deposit } = storeToRefs(store);
      is_collected.value = response.is_collected;
      deposit.value = response.deposit;

      if (!is_collected.value && deposit.value > 0) {
        if (localStorage) {
          let timeout = localStorage.getItem("ny_timeout");
          if (timeout) {
            timeout = parseInt(timeout);
            if (timeout > Date.now()) return;
          }
        }
        $vfm.show("new_year");
      }
    }
  });*/
}

export function serializeBody(obj) {
  return Object.keys(obj).reduce((a, b) => {
    if (obj[b]) a[b] = obj[b];
    return a;
  }, {});
}

export function toLocalDate(dateString, isDate = true, isTime = true, short = false) {
  const date = new Date(dateString);

  const { $i18n } = useNuxtApp();

  const { locale } = $i18n;

  const options = {};
  if (isDate) {
    Object.assign(options, {
      year: "numeric",
      month: short ? "numeric" : "long",
      day: "numeric",
    });
  }
  if (isTime) {
    Object.assign(options, {
      hour: "numeric",
      minute: "numeric",
    });
  }

  const obj = date.toLocaleString(locale.value, options);

  return obj.replace(" г.", "");
}

export function useClick({
  el,
  toggle,
}) {
  const documentClick = (event) => {
    let target = event.target
    if (el && el.value && el.value !== target && !el.value.contains(target)) {
      toggle.value = false
    }
  }

  onMounted(() => {
    document.addEventListener('click', documentClick)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', documentClick)
  })
}

export function checkAuth() {
  const { $auth } = useNuxtApp()
  if (!$auth.loggedIn) {
    $vfm.show('auth')
  }
  return $auth.loggedIn
}

export function slugify(x) {
  return slug(x, {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: false,     // strip special characters except replacement, defaults to `false`
    locale: 'en',      // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
  })
}