import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/builds/novaspin/trix/old-frontend-vue3/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/builds/novaspin/trix/old-frontend-vue3/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/builds/novaspin/trix/old-frontend-vue3/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxtjs_i18n_dist_runtime_plugins_composition_mjs_sLxaNGmlSL from "/builds/novaspin/trix/old-frontend-vue3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_yfWm7jX06p from "/builds/novaspin/trix/old-frontend-vue3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/builds/novaspin/trix/old-frontend-vue3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_axios_plugin_mjs_qrCONXNZyY from "/builds/novaspin/trix/old-frontend-vue3/.nuxt/axios.plugin.mjs";
import _nuxt_auth_plugin_mjs_6DDHMgc34l from "/builds/novaspin/trix/old-frontend-vue3/.nuxt/auth.plugin.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/builds/novaspin/trix/old-frontend-vue3/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_auth_js_ANExhJHvcR from "/builds/novaspin/trix/old-frontend-vue3/plugins/auth.js";
import plugins_axios_js_sVCuMR6hEC from "/builds/novaspin/trix/old-frontend-vue3/plugins/axios.js";
import plugins_centrifugo_js_0QHERrVFYR from "/builds/novaspin/trix/old-frontend-vue3/plugins/centrifugo.js";
import plugins_errorHandler_js_cfkZ75xwSQ from "/builds/novaspin/trix/old-frontend-vue3/plugins/errorHandler.js";
import plugins_eventBus_js_cMx6qAgliS from "/builds/novaspin/trix/old-frontend-vue3/plugins/eventBus.js";
import plugins_fingerprint_client_js_Z9SxJPXU9n from "/builds/novaspin/trix/old-frontend-vue3/plugins/fingerprint.client.js";
import plugins_gtm_client_js_KEcT5dOoKs from "/builds/novaspin/trix/old-frontend-vue3/plugins/gtm.client.js";
import plugins_modal_js_vQuRkj8BKT from "/builds/novaspin/trix/old-frontend-vue3/plugins/modal.js";
import plugins_refs_js_2JopotYXbH from "/builds/novaspin/trix/old-frontend-vue3/plugins/refs.js";
import plugins_router_js_19BuzgFWKZ from "/builds/novaspin/trix/old-frontend-vue3/plugins/router.js";
import plugins_shuffle_seed_js_40xlboRpWW from "/builds/novaspin/trix/old-frontend-vue3/plugins/shuffle-seed.js";
import plugins_toast_js_Zl2rZYKMaV from "/builds/novaspin/trix/old-frontend-vue3/plugins/toast.js";
import plugins_vue_final_modal_js_6X7LqUlyAl from "/builds/novaspin/trix/old-frontend-vue3/plugins/vue-final-modal.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxtjs_i18n_dist_runtime_plugins_composition_mjs_sLxaNGmlSL,
  node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_yfWm7jX06p,
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_axios_plugin_mjs_qrCONXNZyY,
  _nuxt_auth_plugin_mjs_6DDHMgc34l,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_auth_js_ANExhJHvcR,
  plugins_axios_js_sVCuMR6hEC,
  plugins_centrifugo_js_0QHERrVFYR,
  plugins_errorHandler_js_cfkZ75xwSQ,
  plugins_eventBus_js_cMx6qAgliS,
  plugins_fingerprint_client_js_Z9SxJPXU9n,
  plugins_gtm_client_js_KEcT5dOoKs,
  plugins_modal_js_vQuRkj8BKT,
  plugins_refs_js_2JopotYXbH,
  plugins_router_js_19BuzgFWKZ,
  plugins_shuffle_seed_js_40xlboRpWW,
  plugins_toast_js_Zl2rZYKMaV,
  plugins_vue_final_modal_js_6X7LqUlyAl
]