import { defineStore } from "pinia";

export const useGameTournamentsStore = defineStore({
  id: "gameTournaments",
  state: () => {
    return {
      award: {
        place: {
          1: 0,
          2: 0,
        },
      },
      top: [],
    };
  },
  getters: {
    bank: (state) => Object.values(state.award.place).reduce((a, b) => (a += b), 0),
  },
});
