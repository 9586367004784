import { useStore } from '~/store'

export default defineNuxtPlugin((nuxtApp) => {
  
  const store = useStore()

  return {
    provide: {
      modal: {
        toggle: (name, state) => {
          store.modals[name] = state !== undefined ? state : !store.modals[name]
        },
        show: (name, params) => {
          store.modals[name] = true
        },
      },
    },
  }
})
