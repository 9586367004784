<template>
  <div v-if="false" class="snows">
    <div v-for="i in 200" :key="`snow-${i}`" class="snow"></div>
  </div>
  <div v-if="$config.CLOSED" class="tech">
    <h1>{{ $t("page.common.tech") }}</h1>
  </div>
  <NuxtLayout v-else :name="layoutName">
    <NuxtPage keep-alive />
  </NuxtLayout>
  <LazyModalUpdate :clearBuild="clearBuild" v-model="modals.update" />
  <LazyModalCaptcha v-if="$auth.loggedIn" v-model="modals.recaptcha" />
  <LazyModalAuth v-if="!$auth.loggedIn" v-model="modals.auth" />
  <LazyModalProfile v-if="$auth.loggedIn" v-model="modals.profile" />
  <LazyModalRakeback v-if="$auth.loggedIn" v-model="modals.rake" />
  <LazyModalPayment v-if="$auth.loggedIn" v-model="modals.payment" />
  <LazyModalSocials v-if="$auth.loggedIn" v-model="modals.socials" />
  <LazyModalBonusTelegram v-if="$auth.loggedIn" v-model="modals.telegram" />
  <LazyModalSearch v-model="modals.search" />
  <LazyModalDomain v-model="modals.domain" />
  <LazyModalGiveawayCreate v-model="modals.giveaway_create" />
  <LazyModalGiveaway v-model="modals.giveaway" />
  <LazyModalFaq v-model="modals.faq" />
  <LazyModalFair2 v-model="modals.fair2" />
  <LazyModalSeed v-model="modals.seed" />
  <LazyModalPincodeSet v-model="modals.oinn" />
  <LazyModalPincodeUnset v-model="modals.oinn1" />
  <LazyModalPincodeConfirm v-model="modals.oinn2" />
  <div id="promooo" style="display: none">
    <div style="display: none">
      <p>
        Trix Fun, известный как Trix Casino, предлагает широкий выбор популярных и захватывающих игр на своем официальном сайте. В Trix казино вас ждёт уникальная возможность испытать настоящий азарт
        и сорвать крупный куш. Создайте незабываемые моменты и большие выигрыши, наслаждаясь яркими эмоциями и невероятными победами. Официальный сайт Трикс предоставляет доступ к многочисленным играм
        и разнообразным развлечениям. Для удобства пользователей также доступно зеркало Trix, которое позволяет играть в любимые игры без препятствий.
      </p>

      <h1>Трикс официальный сайт казино — испытай свой азарт!</h1>
      <p>
        В казино Трикс мы строго соблюдаем все лицензированные стандарты, обеспечивая честную игру и надежность. Мы предлагаем безопасные способы транзакций и быстрые выплаты, чтобы вы могли
        наслаждаться игрой без лишних забот. Официальный сайт казино Trix защищен современными технологиями, обеспечивающими конфиденциальность ваших данных. Наша профессиональная команда всегда
        готова помочь вам, чтобы ваше время на сайте Трикс было максимально приятным и безопасным.
      </p>

      <h2>Трикс Фан — наше казино заботится о вашей безопасности и защищаем от мошенников</h2>
      <p>
        Мы в онлайн казино Трикс прилагаем все усилия, чтобы защитить наших клиентов от мошенников. Для этого мы регулярно обновляем домены и предоставляем актуальные ссылки на нашем официальном сайте
        и в официальном телеграм-канале Trix. Мы рекомендуем нашим пользователям проверять адрес сайта и использовать только проверенные источники, такие как Trix Casino зеркало, чтобы избежать
        мошенничества. Наша команда также активно информирует клиентов через телеграм-канал, обеспечивая быстрый доступ к актуальному сайту и рабочему зеркалу на сегодня.
      </p>

      <h2>Online casino Trix</h2>
      <p>
        Присоединяйтесь к нам! У нас вас ждут захватывающие игры с живыми дилерами и разнообразные слоты. В нашем live казино с живыми дилерами можно испытать атмосферу настоящего казино, играя в
        рулетку и другие лайв игры. Онлайн казино Трикс предлагает вам возможность играть с реальными дилерами, что добавляет азарта и реализма.
        <br />
        Мы также гордимся нашим выбором лучших игровых слотов, созданных ведущими провайдерами. Наслаждайтесь игрой в демо-режиме или попробуйте свою удачу на реальные деньги. Наше казино предлагает
        только лучшие слоты для незабываемого игрового опыта.
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useStore } from "./store";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { $vfm } from "vue-final-modal";
import ifvisible from "ifvisible.js";
import { useEventListener } from "@vueuse/core";

const { needRefresh, updateServiceWorker } = useRegisterSW({ immediate: true });
// import stats from "@jam3/stats";

// stats();
const modals = ref({
  new_year: false,
});

const clearBuild = async () => {
  if (caches) {
    const keys = await caches.keys();
    if (keys.length) {
      await Promise.all(
        keys.map(async (key) => {
          await caches.delete(key);
        })
      );
    }
  }
  await updateServiceWorker(false);
  window.location.reload();
};

watch(
  needRefresh,
  async (val) => {
    if (val) {
      clearBuild();
    }
  },
  {
    immediate: true,
  }
);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { old, snow, visible } = storeToRefs(store);
const { $axios, $config, $auth } = useNuxtApp();
const layoutName = computed(() => {
  if (old.value) {
    return "default";
  } else {
    return "redesign";
  }
});

const checkYmAvailable = (cb, idx = 0) => {
  if (!window.ym && idx < 10) {
    setTimeout(() => {
      checkYmAvailable(cb, idx++);
    }, 300);
  } else if (window.ym) {
    cb();
  }
};

function sendUserIdToGTM(UserID) {
  window.dataLayer?.push({
    "User-ID": UserID,
    UserID,
    user_id: UserID,
    userId: UserID,
  });
  document.cookie = `UserID=${UserID}; path=/; max-age=99999999`;
}

nextTick(() => {
  watch(
    () => $auth.user?.id,
    (val) => {
      if (val) {
        try {
          sendUserIdToGTM(val);
          checkYmAvailable(() => {
            sendUserId(val);
            sendUserIdToGTM(val);
          });
        } catch (e) {
          //
        }
      }
    },
    {
      immediate: true,
    }
  );

  watch(
    () => [route.name, route.params],
    () => {
      const el = document.querySelector("html");
      if (el) {
        el.scrollTop = 0;
      }
    },
    {
      deep: true,
    }
  );
});

onMounted(() => {
  localStorage.setItem("buildAt", Date.now());
  const disbleSnow = localStorage.getItem("2022");
  if (!disbleSnow) {
    localStorage.setItem("2022", 1);
    localStorage.setItem("snow", false);
    snow.value = false;
  }
  const email = route.query.email;
  const hash = route.query.hash;

  if (email && hash) {
    if ($auth.loggedIn) {
      $axios
        .$post("user/verify_email", {
          email,
          hash,
        })
        .then((x) => {
          if (x.response?.notify?.type === "success") {
            $auth.user.email.verified = true;
          }
        });
    }
    router.replace({
      ...route,
      query: {},
    });
  }
  $axios
    .$get("settings")
    .then(({ response }) => {
      if (response) Object.assign($config.public, response);

      if (response?.CLOSED) {
        document.body.style = "padding: 0";
      }

      const undomain = localStorage.getItem("undomain");
      if (!undomain && response.DOMAIN !== window.location.host && window.location.host !== "trix.casino") {
        $vfm.show("domain");
      }
    })
    .catch(() => {});
  if (!$config.public.dev) {
    const interval = setInterval(() => {
      $axios
        .$get(`${window.location.origin}/version.json?t=${Date.now()}`)
        .then((res) => {
          if (res.buildAt) {
            const lastDate = localStorage.getItem("buildAt");

            if (lastDate) {
              const lastDateNum = parseInt(lastDate);

              if (lastDateNum < res.buildAt) {
                localStorage.setItem("buildAt", res.buildAt);
                clearInterval(interval);
                $vfm.show("update");
              }
            } else {
              localStorage.setItem("buildAt", res.buildAt);
            }
          }
        })
        .catch(() => {});
    }, 1000 * 60 * 1);
  }

  watch(
    () => [route.query.giveaway, route.query.g],
    (v) => {
      const code = v[0] || v[1];
      if (code) $vfm.show("giveaway", { code });
    },
    {
      immediate: true,
    }
  );

  nextTick(() => {
    try {
      ifvisible.on("blur", function () {
        visible.value = false;
      });

      ifvisible.on("focus", function () {
        visible.value = true;
      });
    } catch (e) {}
  });

  useEventListener(window, "message", (e) => {
    if (e.data.message === "slotreload") router.back();
  });
});

// simple dimple not popit
window.addEventListener("error", (error) => {
  console.log("error at window", error);
  console.log("error message at window", error?.message);
  if (
    error?.message?.includes &&
    (error.message.includes("ailed to fetch dynamically imported module") ||
      error.message.includes("mporting a module script failed") ||
      error.message.includes("nable to preload CSS") ||
      error.message.includes("error loading dynamically imported module") ||
      error.message.includes("nvalid or unexpected token") ||
      error.message.includes("is not a valid JavaScript MIME type"))
  ) {
    navigator?.serviceWorker?.getRegistrations().then(async (registrations) => {
      for (let registration of registrations) {
        await registration.unregister();
      }
    });
  }
});
</script>

<style lang="scss">
.tech {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 9999999;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: white;
}

.pwa-toast .message {
  margin-bottom: 8px;
}

.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snows {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  user-select: none;
}

main {
  position: relative;
  z-index: 2;
}

.snow {
  $total: 200;
  position: absolute;
  width: 10px; //20px;
  height: 10px; //20px;
  background: #e8e5f8; //white;
  //background: url(/images/snow.png) no-repeat center center;
  //background-size: 100% 100%;
  border-radius: 50%;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}

.loader {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(@/assets/svg/loader.svg) no-repeat center center, rgba($color: #000000, $alpha: 0.3);
  }
}
</style>
