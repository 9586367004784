<template>
  <div class="flex flex-col">
    <div class="text-[14px]">{{ content }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
    },
  },
}
</script>
