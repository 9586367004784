import { ExpiredAuthSessionError } from "./expired-auth-session-error.mjs";
export class RequestHandler {
  constructor(scheme, axios) {
    this.scheme = scheme;
    this.axios = axios;
    this.interceptor = null;
  }
  setHeader(token) {
    if (this.scheme.options.token && this.scheme.options.token.global) {
      this.axios.setHeader(this.scheme.options.token.name, token);
    }
  }
  clearHeader() {
    if (this.scheme.options.token && this.scheme.options.token.global) {
      this.axios.setHeader(this.scheme.options.token.name, false);
    }
  }
  initializeRequestInterceptor(refreshEndpoint) {
    this.interceptor = this.axios.interceptors.request.use(async (config) => {
      const token = this.scheme.token;
      return this.#getUpdatedRequestConfig(config, token ? token.get() : false);
    });
  }
  reset() {
    this.axios.interceptors.request.eject(this.interceptor);
    this.interceptor = null;
  }
  #needToken(config) {
    const options = this.scheme.options;
    return (
      options.token.global ||
      Object.values(options.endpoints).some((endpoint) =>
        typeof endpoint === "object"
          ? endpoint.url === config.url
          : endpoint === config.url
      )
    );
  }
  #getUpdatedRequestConfig(config, token) {
    if (typeof token === "string") {
      config.headers[this.scheme.options.token.name] = token;
    }
    return config;
  }
  #requestHasAuthorizationHeader(config) {
    return !!config.headers.common[this.scheme.options.token.name];
  }
}
