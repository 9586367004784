import { defineStore } from "pinia";
export const useStore = defineStore({
  id: "index",
  state: () => {
    return {
      oldHome: window.innerWidth < 980,
      old: false, // localStorage.getItem("old") === null ? false : localStorage.getItem("old") === "true",
      dark: localStorage.getItem("dark") === null ? false : localStorage.getItem("dark") === "true",
      modals: {
        auth: false,
      },
      is_collected: false,
      deposit: 0,
      popupSlot: localStorage.getItem("popupSlot") === null ? false : localStorage.getItem("popupSlot") === "true",
      snow: localStorage.getItem("snow") === null ? false : localStorage.getItem("snow") === "true",
      visible: true,
      logo: {
        domain: localStorage.getItem("logo.domain") === null ? true : localStorage.getItem("logo.domain") === "true",
        blur: localStorage.getItem("logo.blur") === null ? false : localStorage.getItem("logo.blur") === "true",
      },
    };
  },
  actions: {},
  mutations: {},
});
